import { Grid } from "@mui/material";
import React from "react";
import { ProfileContext } from "../../App";
import { isMobile } from "react-device-detect";

//This should only be loaded if user clicks on "show chat/video or something similar, cause it loads a lot MB"
export function Video() {
  /*  const profileData = React.useContext(ProfileContext);

  //if maxis profil -> for testing show synthesia video
  let maxiProfilId = "4f2813c1-0a1d-f847-3b8d-078ba4d2e1a3";
  if (profileData?.getUserProfile?.id === maxiProfilId) {
    return (
      <Grid style={{ textAlign: "center" }}>
        <iframe
          src="https://share.synthesia.io/embeds/videos/e18a5905-9133-490a-a397-997e4ec18597"
          title="Synthesia video player - DBC_prototype_template"
          style={{
            position: "static",
            paddingTop: "100px",
            marginBottom: "-200px",
            marginTop: "50px",
            border: "none",
            width: { isMobile } ? "100%" : "50%",
            height: "50vh",
          }}
        ></iframe>
      </Grid>
    );
  } */

  return <></>;
}
export default Video;
