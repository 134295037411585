import { Domain } from ".";

export default {
  [Domain.General]: {
    certificates: "Zertifikate",
    experience: "Erfahrung und Fachgebiete",
    experience_only: "Werdegang",
    other_skills: "Weitere Kenntnisse",
    special_field: "Fachgebiete",
    book_me_field: "Buch mich",
    footer: {
      headers: {
        about_us: "Über uns",
        services: "Dienste",
        products: "Produkte",
        social_media: "Soziale Medien",
      },
      labels: {
        company: "Unternehmen",
        contact: "Kontakt",
        blog: "Blog",
        career: "Jobs",
        digital_transformation: "Digitale Transformation",
        business_consulting: "Business Consulting",
        technology_services: "Technology Services",
        cloud_services: "Cloud & Data Services",
        ecm_services: "Enterprise Content Management",
        imprint: "Impressum",
      },
    },
    links: {
      company: "https://www.fme.de/aktuell/",
      blog: "https://content.fme.de/blog",
      contact: "https://www.fme.de/kontakt/",
      career: "https://www.fme-karriere.de/",
      digital_transformation: "https://www.fme.de/digitale-transformation/",
      business_consulting: "https://www.fme.de/dienstleistungen/business-consulting/",
      technology_services: "https://www.fme.de/dienstleistungen/technology-services/",
      cloud_services: "https://www.fme.de/dienstleistungen/technology-services/cloud-technologien/",
      ecm_services: "https://www.fme.de/dienstleistungen/technology-services/enterprise-content-management/",
      dqman: "https://www.fme.de/produkte/dqman/",
      migration_center: "https://www.fme.de/produkte/migration-center/",
      vistaya: "https://www.fme.de/produkte/vistaya/",
      imprint: "https://www.fme.de/impressum/",
    },
    links_social: {
      youtube: "https://www.youtube.com/user/fmegroup",
      facebook: "https://www.facebook.com/fme.ag.de",
      instagram: "https://www.instagram.com/fme_group",
      linkedin: "https://www.linkedin.com/company/fme-ag",
      twitter: "https://twitter.com/fmeag_de",
      xing: "https://www.xing.com/pages/fmeag",
    },
  },
};