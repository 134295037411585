import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Tab, Tabs, TabProps, Typography } from "@mui/material";
import {
  WorkspacePremiumOutlined,
  ComputerOutlined,
  AddBoxOutlined,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { Domain } from "../../common/i18n/";

const LinkTab = (props: TabProps<typeof Link>) => (
  <Tab {...props} component={Link} />
);

export function Navigation() {
  const [value, setValue] = React.useState(1);
  const { t } = useTranslation(Domain.General);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AppBar
      position="relative"
      color="transparent"
      style={{ marginBottom: "1em", top: "10vh" }}
    >
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="icon label tabs example"
      >
        <LinkTab
          centerRipple
          icon={<WorkspacePremiumOutlined />}
          label={<Typography variant="h4">
            {t("certificates")}
          </Typography>}
          to="/certificates"
        />
        <LinkTab
          centerRipple
          icon={<ComputerOutlined />}
          label={<Typography variant="h4">
            {t("experience")}
          </Typography>}
          to="/experience"
        />
        <LinkTab
          centerRipple
          icon={<AddBoxOutlined />}
          label={<Typography variant="h4">
            {t("other_skills")}
          </Typography>}
          to="/skills"
        />
      </Tabs>
    </AppBar>
  );
}

export default Navigation;
