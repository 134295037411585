/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const generatePresignedImageUrl = /* GraphQL */ `query GeneratePresignedImageUrl($profileId: String) {
  generatePresignedImageUrl(profileId: $profileId) {
    body {
      message
      url
      __typename
    }
    statusCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GeneratePresignedImageUrlQueryVariables,
  APITypes.GeneratePresignedImageUrlQuery
>;
export const generatePresignedAudioUrl = /* GraphQL */ `query GeneratePresignedAudioUrl($profileId: String, $lang: String) {
  generatePresignedAudioUrl(profileId: $profileId, lang: $lang) {
    body {
      message
      url
      __typename
    }
    statusCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GeneratePresignedAudioUrlQueryVariables,
  APITypes.GeneratePresignedAudioUrlQuery
>;
export const getUserProfileAddons = /* GraphQL */ `query GetUserProfileAddons($id: ID!) {
  getUserProfileAddons(id: $id) {
    id
    profileId
    topSkills
    phoneNumber
    m365BookingLink
    mobileNumber
    address {
      company
      plz_city
      street
      homepage
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileAddonsQueryVariables,
  APITypes.GetUserProfileAddonsQuery
>;
export const listUserProfileAddons = /* GraphQL */ `query ListUserProfileAddons(
  $filter: ModelUserProfileAddonsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProfileAddons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      profileId
      topSkills
      phoneNumber
      m365BookingLink
      mobileNumber
      address {
        company
        plz_city
        street
        homepage
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfileAddonsQueryVariables,
  APITypes.ListUserProfileAddonsQuery
>;
export const getUserProfile = /* GraphQL */ `query GetUserProfile($id: ID!) {
  getUserProfile(id: $id) {
    id
    profileId
    userId
    name
    mail
    created
    type
    version
    lastChanged
    language
    status
    common {
      name
      title
      birthYear
      __typename
    }
    career {
      title
      entries {
        year
        title
        __typename
      }
      __typename
    }
    skillsets {
      category
      skills
      subcategories {
        category
        skills
        __typename
      }
      __typename
    }
    activities {
      title
      entries {
        description
        from
        to
        projectRef
        archived
        roles
        tasks
        customer {
          industry
          __typename
        }
        technologies
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileQueryVariables,
  APITypes.GetUserProfileQuery
>;
export const listUserProfiles = /* GraphQL */ `query ListUserProfiles(
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      profileId
      userId
      name
      mail
      created
      type
      version
      lastChanged
      language
      status
      common {
        name
        title
        birthYear
        __typename
      }
      career {
        title
        entries {
          year
          title
          __typename
        }
        __typename
      }
      skillsets {
        category
        skills
        subcategories {
          category
          skills
          __typename
        }
        __typename
      }
      activities {
        title
        entries {
          description
          from
          to
          projectRef
          archived
          roles
          tasks
          customer {
            industry
            __typename
          }
          technologies
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfilesQueryVariables,
  APITypes.ListUserProfilesQuery
>;
