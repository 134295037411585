import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import { Amplify, API } from "aws-amplify";
import { Cache } from "aws-amplify";

import theme from "./common/theme";
import MainView from "./pages/MainView";
import DigitalBusinessCard from "./pages/DigitalBusinessCard";
import awsExports from "./common/aws-exports";
import * as queries from "./graphql/queries";
import {
  GeneratePresignedAudioUrlQuery,
  GeneratePresignedImageUrlQuery,
  GetUserProfileAddonsQuery,
  GetUserProfileQuery,
} from "./API";
import ErrorView from "./components/ErrorView/ErrorView";
import LoadingView from "./components/LoadingView/LoadingView";

Amplify.configure(awsExports);

export const ProfileContext = React.createContext<GetUserProfileQuery | null>(
  null
);

export const ProfileAddonContext =
  React.createContext<GetUserProfileAddonsQuery | null>(null);

export const ImageContext =
  React.createContext<GeneratePresignedImageUrlQuery | null>(null);

export const AudioContext =
  React.createContext<GeneratePresignedAudioUrlQuery | null>(null);

function App() {
  var profileId = "";
  var currentPath = window.location.pathname.replaceAll("/", "");
  var listOfOtherLocations = ["dba", "test"];
  var no_cache_setting = "?nocache"; //TBD - cleaner..
  var dbcResource = "card";
  if (window.location.toString().endsWith(no_cache_setting)) {
    Cache.clear();
  }

  if (!listOfOtherLocations.includes(currentPath)) {
    //get the id from the path in link
    profileId = window.location.pathname
      .replaceAll("/", "")
      .replaceAll("card", "");
  }

  const [userData, setUserData] = React.useState<any>(null);
  const [userAddonData, setUserAddonData] = React.useState<any>(null);
  const [urlImageData, setUrlImageData] = React.useState<any>(null);
  const [urlAudioData, setUrlAudioData] = React.useState<any>(null);

  useEffect(() => {
    const getProfileData = async () => {
      const data = await API.graphql({
        query: queries.getUserProfile,
        variables: { id: profileId },
      });
      const { data: innerData } = (data as any)!;
      setUserData(innerData);
      console.log("set profileId to amplify-cache");
      let expiration = new Date();
      expiration.setFullYear(expiration.getFullYear() + 3);
      Cache.setItem("profileId", profileId, { expires: expiration.getTime() });
    };

    const getProfileAddonData = async () => {
      const dataAddon = await API.graphql({
        query: queries.getUserProfileAddons,
        variables: { id: profileId },
      });
      const { data: innerData } = (dataAddon as any)!;
      setUserAddonData(innerData);
    };

    const getUrlImageData = async () => {
      const dataImage = await API.graphql({
        query: queries.generatePresignedImageUrl,
        variables: { profileId: profileId },
      });
      const { data: innerData } = (dataImage as any)!;
      setUrlImageData(innerData);
    };

    const getUrlAudioData = async () => {
      const dataAudio = await API.graphql({
        query: queries.generatePresignedAudioUrl,
        variables: { profileId: profileId, lang: "de" }, //TBD - language implementation for english! (not yet implemented in Lambda)
      });
      const { data: innerData } = (dataAudio as any)!;
      setUrlAudioData(innerData);
    };

    getProfileData();
    getProfileAddonData();
    getUrlImageData();
    getUrlAudioData();
  }, [profileId]);

  if (!listOfOtherLocations.includes(currentPath)) {
    let storageId = Cache.getItem("profileId");
    var checkProfile = checkProfileValid(profileId);
    if (checkProfile[0] === false) {
      if (storageId === "" || storageId === null) {
        console.log("no profile yet stored");
        return showErrorMessage(checkProfile[1]);
      } else {
        profileId = storageId!;
        console.log("profile read from storage: " + profileId);
      }
    }
  } else {
    //this is very unclean, but i dont know better, but it works and maybe some one knows a better way to do
    /*  if (currentPath === "dba") {
      //TBD: Not working anymore :(
      if (!userData || !userAddonData) {
        return showErrorMessage(
          "No user specified via url before calling dba endpoint.."
        );
      }
      console.log("open dba view");
      return (
        <ProfileContext.Provider value={userData}>
          <ProfileAddonContext.Provider value={userAddonData}>
            <ImageContext.Provider value={urlImageData}>
              <AudioContext.Provider value={urlAudioData}>
                <ThemeProvider theme={theme}>
                  <DigitalBusinessCard />
                </ThemeProvider>
              </AudioContext.Provider>
            </ImageContext.Provider>
          </ProfileAddonContext.Provider>
        </ProfileContext.Provider>
      );
    } */
  }

  if (!userData || !userAddonData) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingView />
      </ThemeProvider>
    );
  }

  if (userData.getUserProfile === null) {
    console.log("no valid profile");
    return showErrorMessage("No data for user found..");
  }

  //TBD: What to do if no addon-data was found?! -> just show the digital business card..
  //UPDATE - 22.04.23 mkrone: no, everyone needs some additional data (telephone..) But we need to look at other things like certs etc
  //so i leave that message for now!
  if (userAddonData.getUserProfileAddons === null) {
    return showErrorMessage("No addon-data for user found..");
  }

  let cardView = false;
  if (
    currentPath.endsWith(dbcResource) ||
    userData.getUserProfile.activities.entries.length === 0
  ) {
    cardView = true;
  }
  return (
    <ProfileContext.Provider value={userData}>
      <ProfileAddonContext.Provider value={userAddonData}>
        <ImageContext.Provider value={urlImageData}>
          <AudioContext.Provider value={urlAudioData}>
            <ThemeProvider theme={theme}>
              <Router>
                <Routes>
                  <Route
                    path="/digitalbusinesscard"
                    element={<DigitalBusinessCard />}
                  />
                  <Route path="*" element={<MainView isDBCOnly={cardView} />} />
                </Routes>
              </Router>
            </ThemeProvider>
          </AudioContext.Provider>
        </ImageContext.Provider>
      </ProfileAddonContext.Provider>
    </ProfileContext.Provider>
  );
}

//This function checks if a profile is valid.
//First we check for emtpy input, then for length of string. At the end we use regex to check if its a valid uuid.
function checkProfileValid(profileId: string): [boolean, string] {
  console.log("check profileid " + profileId);
  if (profileId === "") {
    return [
      false,
      "You did not specify any Profile-Id inside the URL.<br>Please specify a valid Profile in format {Hostname}:/{ProfileID}!",
    ];
  }
  if (profileId.length !== 36) {
    return [
      false,
      "You did not specify an valid Profile-Id inside the URL.<br>Please specify a valid one!",
    ];
  }
  const uidRegexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  if (!uidRegexExp.test(profileId)) {
    return [
      false,
      "You did not specify an valid uuid as the Profile-Id.<br>Please specify a valid one!",
    ];
  }
  return [true, ""];
}

function showErrorMessage(message: string) {
  return (
    <ThemeProvider theme={theme}>
      <ErrorView message={message} />
    </ThemeProvider>
  );
}

export default App;
