import { Grid, IconButton } from "@mui/material";
import ChatApp from "./../ChatBubble/index";
import React from "react";
import { AudioContext, ProfileAddonContext, ProfileContext } from "./../../App";

import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseIcon from "@mui/icons-material/Pause";
import Separator from "../Seperator/Separator";
import { UserProfile } from "../../models/UserProfile";

export function Popup() {
  /* enum AudioState {
    paused = "paused",
    playing = "playing",
  } */
  const profileData = React.useContext(ProfileContext);
  const profileAddonData = React.useContext(ProfileAddonContext);

  const profile = new UserProfile(
    profileData?.getUserProfile!,
    profileAddonData?.getUserProfileAddons!,
    false
  );

  const audioData = React.useContext(AudioContext);
  //var state = AudioState.paused;

  function play() {
    //state = AudioState.playing;
    var doc = document.getElementById("audio_obj") as HTMLAudioElement;
    doc.play();
  }

  function pause() {
    //pause no matter if already started, because can also be started via chatbot.
    //state = AudioState.paused;
    var doc = document.getElementById("audio_obj") as HTMLAudioElement;
    doc.pause();
  }

  //reset state to pause
  /* function ended() {
    state = AudioState.paused;
  } */

  return (
    <>
      <Grid width="100%" height="100%">
        <IconButton onClick={play} color="primary">
          <PlayArrowRoundedIcon />
        </IconButton>
        <IconButton onClick={pause} color="primary">
          <PauseIcon />
        </IconButton>
        {audioData?.generatePresignedAudioUrl?.body?.url ? (
          <audio
            id="audio_obj"
            controls={false}
            src={audioData.generatePresignedAudioUrl.body.url}
            //onEnded={ended}
          />
        ) : null}
        <ChatApp name={profile.FirstName} />
        <Separator />
      </Grid>
    </>
  );
}

export default Popup;
