import React from "react";
import { Container } from "@mui/system";
import {
  //MemoryRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  Certificates,
  Experience,
  Header,
  Navigation,
  CardView,
  OtherSkills,
  BottomView,
  Video,
} from "../components";
import Footer from "../components/Footer/Footer";
import { IconButton, Typography } from "@mui/material";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";

export interface MainViewProps {
  isDBCOnly: boolean;
}

export const MainView = ({ isDBCOnly }: MainViewProps) => {
  const [isDBC, setIsDBCOnly] = React.useState<boolean>(isDBCOnly);

  function switchCardMode(): void {
    setIsDBCOnly(!isDBC);
  }

  return (
    <div>
      <Header />
      {!isDBC ? (
        <Typography>
          <IconButton
            id="switchMode"
            onClick={switchCardMode}
            color="primary"
            sx={{ "&:hover": { color: "red" } }}
          >
            <BadgeRoundedIcon
              style={{
                fontSize: "xx-large",
                color: "primary",
              }}
            />
          </IconButton>
        </Typography>
      ) : (
        ""
      )}
      <CardView isDigitalBusinessCard={false} isDBCOnly={isDBC} />
      {isDBC ? (
        <Footer />
      ) : (
        <div>
          <Navigation />
          <Container>
            <Routes>
              <Route path="/certificates" element={<Certificates />} />
              <Route path="/experience" element={<Experience />} />
              <Route path="/skills" element={<OtherSkills />} />

              <Route path="*" element={<Navigate to="/experience" />} />
              <Route path="dbc" element={<Navigate to="" />} />
            </Routes>
            <Video />
          </Container>
          <BottomView />
        </div>
      )}
    </div>
  );
};

export default MainView;
