import { formControlUnstyledClasses } from "@mui/base";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const colors = {
  red: "#e60000",
  pink: "#e5186b"
};

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "3rem",
      fontFamily: "source sans pro semibold"
    },
    h2: {
      fontSize: "2.25rem",
      fontFamily: "source sans pro semibold"
    },
    h3: {
      fontSize: "1.5rem",
      letterSpacing: "1px",
      fontFamily: "source sans pro semibold"
    },
    h4: {
      fontSize: "1.25rem",
      fontFamily: "source sans pro light"
    },
    h5: {
      fontSize: "1rem",
      letterSpacing: "0.75px",
      fontFamily: "source sans pro light"
    },
    h6: {
      fontSize: ".7rem",
      fontFamily: "source sans pro light"
    },
    body1: {
      fontSize: "1rem",
      fontFamily: "source sans pro"
    },
    body2: {
      fontSize: "1rem",
      fontFamily: "source sans pro",
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: colors.red,
    },
    secondary: {
      main: colors.pink,
    },
  },
  components: {},
});

export default responsiveFontSizes(theme);
