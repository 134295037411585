import { Domain } from ".";

export default {
  [Domain.General]: {
    certificates: "Certificates",
    experience: "Experience and Knowledge",
    experience_only: "Career",
    other_skills: "Other skills",
    special_field: "Special field",
    book_me_field: "Book me",
    footer: {
      headers: {
        about_us: "About us",
        services: "Services",
        products: "Products",
        social_media: "Social Media",
      },
      labels: {
        company: "Company",
        contact: "Concact",
        blog: "Blog",
        career: "Jobs",
        digital_transformation: "Digitale Transformation",
        business_consulting: "Business Consulting",
        technology_services: "Technology Services",
        cloud_services: "Cloud & Data Services",
        ecm_services: "Enterprise Content Management",
        imprint: "Imprint",
      }
    },
    links: {
      company: "https://www.fme.de/en/about-fme/",
      blog: "https://content.fme.de/en/blog",
      contact: "https://www.fme.de/en/contact/",
      career: "https://www.fme.de/en/career/",
      digital_transformation: "https://www.fme.de/en/digital-transformation/",
      business_consulting: "https://www.fme.de/en/services/business-consulting/",
      technology_services: "https://www.fme.de/en/services/technology-services/",
      cloud_services: "https://www.fme.de/en/services/technology-services/cloud-technologies/",
      ecm_services: "https://www.fme.de/en/services/technology-services/enterprise-content-management/",
      dqman: "https://www.fme.de/en/products/dqman/",
      migration_center: "https://www.fme.de/en/products/migration-center/",
      vistaya: "https://www.fme.de/en/products/vistaya/",
      imprint: "https://en.fme.de/imprint/",
    },
    links_social: {
      youtube: "https://www.youtube.com/user/fmegroup",
      facebook: "https://www.facebook.com/fme.ag.de",
      instagram: "https://www.instagram.com/fme_group",
      linkedin: "https://www.linkedin.com/company/fme-ag",
      twitter: "https://twitter.com/fmeag_de",
      xing: "https://www.xing.com/pages/fmeag",
    },
  },
};