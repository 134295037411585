// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  //aws_appsync_graphqlEndpoint:
  //  "https://bhoduhamk5gs5nmvouju3hwtpm.appsync-api.eu-central-1.amazonaws.com/graphql",
  //aws_appsync_apiKey: "da2-jl7tkxsrd5d47ngvsapzwtgvui",
  aws_appsync_apiKey: "da2-u6upuntb2ncpjfhjvipppzyuru",
  aws_appsync_graphqlEndpoint:
    "https://xybuegkejbebpglbry6w3dbowa.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "API_KEY",
};
export default awsmobile;
